import React, { useState, ChangeEvent } from "react";
import { storage, db } from "../firebase-config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, addDoc } from "firebase/firestore";

// Updated Project interface to include status
interface Project {
  title: string;
  description?: string;
  srcImg: string;
  images: string[];
  videos: string[];
  status: "Completed" | "Ongoing" | "Yet to Start";
}

const App: React.FC = () => {
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [srcImg, setSrcImg] = useState<File | null>(null);
  const [images, setImages] = useState<File[]>([]);
  const [videos, setVideos] = useState<File[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);

  // New state for project status
  const [status, setStatus] = useState<Project["status"]>("Yet to Start");

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setImages([...e.target.files]);
    }
  };

  const handleVideoChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setVideos([...e.target.files]);
    }
  };

  const handleSrcImgChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSrcImg(e.target.files[0]);
    }
  };

  const uploadProject = async () => {
    if (!title || !srcImg || (images.length === 0 && videos.length === 0)) {
      alert(
        "Please provide a title, select a primary image, and upload additional images or videos."
      );
      return;
    }

    setUploading(true);

    try {
      const imageUrls: string[] = [];
      const videoUrls: string[] = [];

      // Upload primary image (srcImg) to Firebase Storage
      const srcImgRef = ref(storage, `projects/${title}/srcImg/${srcImg.name}`);
      await uploadBytes(srcImgRef, srcImg);
      const srcImgURL = await getDownloadURL(srcImgRef);

      // Upload additional images to Firebase Storage
      for (const image of images) {
        const imageRef = ref(storage, `projects/${title}/images/${image.name}`);
        await uploadBytes(imageRef, image);
        const downloadURL = await getDownloadURL(imageRef);
        imageUrls.push(downloadURL);
      }

      // Upload videos to Firebase Storage
      for (const video of videos) {
        const videoRef = ref(storage, `projects/${title}/videos/${video.name}`);
        await uploadBytes(videoRef, video);
        const downloadURL = await getDownloadURL(videoRef);
        videoUrls.push(downloadURL);
      }

      // Save project metadata to Firestore, now including status
      await addDoc(collection(db, "projects"), {
        title,
        description,
        srcImg: srcImgURL,
        images: imageUrls,
        videos: videoUrls,
        status, // Add the new status field
      });

      alert("Project uploaded successfully!");
      setTitle("");
      setDescription("");
      setSrcImg(null);
      setImages([]);
      setVideos([]);
      setStatus("Yet to Start"); // Reset status to default
    } catch (error) {
      console.error("Error uploading project:", error);
      alert("Failed to upload project.");
    } finally {
      setUploading(false);
    }
  };

  return (
    <div
      style={{
        padding: "20px",
        maxWidth: "600px",
        margin: "auto",
        fontFamily: "Arial, sans-serif",
      }}
    >
      <h1 style={{ textAlign: "center", color: "#333" }}>Upload Project</h1>

      <label style={{ display: "block", margin: "10px 0" }}>
        <strong>Project Title:</strong>
      </label>
      <input
        type="text"
        placeholder="Enter project title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        style={{
          width: "100%",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "5px",
          border: "1px solid #ccc",
        }}
      />

      <label style={{ display: "block", margin: "10px 0" }}>
        <strong>Description (Optional):</strong>
      </label>
      <textarea
        placeholder="Enter project description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        style={{
          width: "100%",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "5px",
          border: "1px solid #ccc",
          resize: "none",
        }}
      />

      {/* New dropdown for project status */}
      <label style={{ display: "block", margin: "10px 0" }}>
        <strong>Project Status:</strong>
      </label>
      <select
        value={status}
        onChange={(e) => setStatus(e.target.value as Project["status"])}
        style={{
          width: "100%",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "5px",
          border: "1px solid #ccc",
        }}
      >
        <option value="Yet to Start">Yet to Start</option>
        <option value="Ongoing">Ongoing</option>
        <option value="Completed">Completed</option>
      </select>

      <label style={{ display: "block", margin: "10px 0" }}>
        <strong>Primary Image:</strong>
      </label>
      <input
        type="file"
        accept="image/*"
        onChange={handleSrcImgChange}
        style={{ display: "block", marginBottom: "10px" }}
      />

      <label style={{ display: "block", margin: "10px 0" }}>
        <strong>Additional Images:</strong>
      </label>
      <input
        type="file"
        multiple
        accept="image/*"
        onChange={handleFileChange}
        style={{ display: "block", marginBottom: "10px" }}
      />

      <label style={{ display: "block", margin: "10px 0" }}>
        <strong>Additional Videos:</strong>
      </label>
      <input
        type="file"
        multiple
        accept="video/*"
        onChange={handleVideoChange}
        style={{ display: "block", marginBottom: "10px" }}
      />

      <button
        onClick={uploadProject}
        disabled={uploading}
        style={{
          width: "100%",
          padding: "10px",
          backgroundColor: uploading ? "#ccc" : "#4CAF50",
          color: "white",
          border: "none",
          borderRadius: "5px",
          cursor: uploading ? "not-allowed" : "pointer",
        }}
      >
        {uploading ? "Uploading..." : "Upload Project"}
      </button>

      {images.length > 0 && (
        <div style={{ marginTop: "20px" }}>
          <h3>Selected Images:</h3>
          <ul>
            {Array.from(images).map((file, idx) => (
              <li key={idx} style={{ marginBottom: "5px" }}>
                {file.name}
              </li>
            ))}
          </ul>
        </div>
      )}

      {videos.length > 0 && (
        <div style={{ marginTop: "20px" }}>
          <h3>Selected Videos:</h3>
          <ul>
            {Array.from(videos).map((file, idx) => (
              <li key={idx} style={{ marginBottom: "5px" }}>
                {file.name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default App;
