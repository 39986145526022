import { collection, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../firebase-config";
import styles from "./Projects.module.scss";
import NavMenuDropdown from "../componenents/NavMenuComp";

interface Project {
  title: string;
  srcImg: string;
  status: string;
  images: string[];
  description: string;
  videos: string[];
}

const ProjectsGrid = () => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [showDropdown, toggleDropdown] = useState(false);

  useEffect(() => {
    const fetchProjects = async () => {
      const projectsCollection = collection(db, "projects");
      const projectsSnapshot = await getDocs(projectsCollection);
      const projectsList = projectsSnapshot.docs.map(
        (doc) => doc.data() as any
      );
      setProjects(projectsList);
    };
    fetchProjects();
  }, []);

  return (
    <div className={styles["projects-wrapper"]}>
      <NavMenuDropdown
        showDropdown={showDropdown}
        toggleDropdown={toggleDropdown}
      />
      <h1>PROJECTS</h1>
      <div className={styles.projectsContainer}>
        {projects.map((project, idx) => (
          <div key={idx} className={styles.projectItem}>
            <div className={styles.projectHeader}>
              <h2 className={styles.projectTitle}>{project.title}</h2>
              <span className={styles.projectStatus}>{project.status}</span>
            </div>

            <div className={styles.imageScroller}>
              <div className={styles.imageContainer}>
                {/* Source Image First */}
                <div className={styles.imageWrapper}>
                  <img
                    src={project.srcImg}
                    alt={`${project.title} main`}
                    className={styles.projectImage}
                  />
                </div>

                {/* Other Images */}
                {project.images.map((image, index) => (
                  <div key={index} className={styles.imageWrapper}>
                    <img
                      src={image}
                      alt={`${project.title} ${index + 1}`}
                      className={styles.projectImage}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectsGrid;
