import React, { useRef } from "react";
import styles from "./TotalProjectsCount.module.scss";
import { motion, useScroll, useTransform } from "framer-motion";

const TotalProjectsCount = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const targetRef = useRef<HTMLDivElement>(null);

  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["center end", "end end"],
  });

  const scale = useTransform(scrollYProgress, [0.3, 1], [1, 3.00967]);
  const rotate = useTransform(scrollYProgress, [0.3, 1], [0, 50]);
  const opacity = useTransform(scrollYProgress, [0, 0.3], [1, 0]);

  return (
    <div className={styles["main-wrapper"]} ref={containerRef}>
      <div className={styles["video-wrapper"]}>
        <video height="100%" width="90%" autoPlay muted loop>
          <source
            src="https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/WhatsApp%20Video%202024-12-07%20at%209.48.13%20AM.mp4?alt=media&token=59480cbd-0065-4406-bf33-5d5da1131d68"
            type="video/mp4"
          />
        </video>
      </div>
      <div className={styles["count-wrapper"]} ref={targetRef}>
        <div className={styles["a80-wrapper"]}>
          <div>
            <motion.div style={{ opacity }} className={styles["extra-content"]}>
              <span>7,00,000+</span> <br />
              SQ.FT
            </motion.div>
          </div>
          <div>
            <div className={styles["text-wrapper"]}>
              <motion.div style={{ opacity }} className={styles["pl"]}>
                +
              </motion.div>
              <motion.div
                style={{
                  scale,
                  rotate,
                }}
                className={styles["text-style"]}
              >
                <span className={styles["eighty_mb"]}>255</span>
              </motion.div>
            </div>
            <motion.div style={{ opacity }} className={styles["text-label"]}>
              happy <br /> customers
            </motion.div>
          </div>
          <div>
            <motion.div style={{ opacity }} className={styles["extra-content"]}>
              <span>99%</span> <br />
              Building Control <br /> Approval Rate
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalProjectsCount;
