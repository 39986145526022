import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBncr5bVBA8oyO83BCV6QG60M4xtJQ3NZk",
  authDomain: "heavenly-group.firebaseapp.com",
  projectId: "heavenly-group",
  storageBucket: "heavenly-group.appspot.com",
  messagingSenderId: "512677721654",
  appId: "1:512677721654:web:f6fe3163fe9146c3314a97",
  measurementId: "G-4Z9489P9Z7",
};
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const db = getFirestore(app);
