import Lenis from "lenis";
import "./ParallaxImages.scss";
import { useEffect, useRef, useState } from "react";
import { useTransform, useScroll, motion, MotionValue } from "framer-motion";

const images: string[] = [
  "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/Parallax%20Images%2FWhatsApp%20Image%202024-11-16%20at%205.06.23%20PM.jpeg?alt=media&token=a95266e2-0045-4a68-876e-c49e096164d3",
  "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/Parallax%20Images%2FWhatsApp%20Image%202024-11-16%20at%205.06.25%20PM.jpeg?alt=media&token=40bb871f-8345-4099-8244-61c25635725e",
  "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/Parallax%20Images%2FWhatsApp%20Image%202024-11-16%20at%205.06.28%20PM.jpeg?alt=media&token=0258253d-a67c-4fd9-8ce4-1dad4862500c",
  "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/Parallax%20Images%2FWhatsApp%20Image%202024-11-16%20at%205.06.37%20PM%20(1).jpeg?alt=media&token=f1d439bd-5685-4e71-af17-19323846299b",
  "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/Parallax%20Images%2FWhatsApp%20Image%202024-11-16%20at%205.06.37%20PM.jpeg?alt=media&token=d01d6d0b-ffad-4a77-b8b9-e3b64e95ce49",
  "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/Parallax%20Images%2FWhatsApp%20Image%202024-11-16%20at%205.06.38%20PM.jpeg?alt=media&token=d58f76c3-73da-47a2-8f2a-c8845ecf9983",
  "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/Parallax%20Images%2FWhatsApp%20Image%202024-11-16%20at%205.06.39%20PM.jpeg?alt=media&token=160ad408-185f-48ff-9b0d-d5af94ced1fd",
  "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/Parallax%20Images%2FWhatsApp%20Image%202024-11-16%20at%205.06.41%20PM.jpeg?alt=media&token=9f6d0de3-489f-474f-9bb7-970a66e792c7",
  "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/Parallax%20Images%2FWhatsApp%20Image%202024-11-16%20at%205.06.43%20PM.jpeg?alt=media&token=45057064-f258-4aef-9e61-31d666079c60",
  "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/Parallax%20Images%2FWhatsApp%20Image%202024-11-17%20at%2010.13.16%20AM%20(1).jpeg?alt=media&token=9f394604-056e-4fa7-b160-fb8acdfa48c1",
  "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/Parallax%20Images%2FWhatsApp%20Image%202024-11-17%20at%2010.13.16%20AM%20(2).jpeg?alt=media&token=4401049f-606f-4280-b1bc-1be62c086069",
  "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/Parallax%20Images%2FWhatsApp%20Image%202024-11-17%20at%2010.13.16%20AM%20(4).jpeg?alt=media&token=84cedb5e-3690-488f-bc97-d5e3b47697d4",
  "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/Parallax%20Images%2FWhatsApp%20Image%202024-11-17%20at%2010.13.16%20AM.jpeg?alt=media&token=a6df0430-d2f8-4a1d-ad25-c61f27b406ca",
];

export default function Home(): JSX.Element {
  const container = useRef<HTMLDivElement>(null);

  const [fixedHeight, setFixedHeight] = useState(0);

  const { scrollYProgress } = useScroll({
    target: container,
    offset: ["start end", "end start"],
  });

  useEffect(() => {
    // Capture the height once when the component mounts
    const height = window.visualViewport?.height || window.innerHeight;
    setFixedHeight(height);
  }, []);

  const y1 = useTransform(scrollYProgress, [0, 1], [0, fixedHeight * 1.2]);
  const y2 = useTransform(scrollYProgress, [0, 1], [0, fixedHeight * 1.4]);

  return (
    <div className="gallery" ref={container}>
      <div className="galleryWrapper">
        <Column images={images.slice(0, Math.ceil(images.length / 2))} y={y1} />
        <Column images={images.slice(Math.ceil(images.length / 2))} y={y2} />
      </div>
    </div>
  );
}

interface ColumnProps {
  images: (string | undefined)[];
  y: MotionValue<number>;
}

const Column = ({ images, y }: ColumnProps): JSX.Element => {
  return (
    <motion.div style={{ y }} className="column">
      {images.map((src, i) => (
        <div key={i} className="imageContainer">
          {src && <img src={src} alt={`alt ${i}`} />}
        </div>
      ))}
    </motion.div>
  );
};
