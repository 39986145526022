interface IProject {
  title: string;
  description: string;
  srcImage: string;
  images: string[];
  subTitile?: string;
}

const projects: Array<IProject> = [
  {
    title: "Modern Living",
    subTitile: "Modern Living",
    description:
      "Experience the harmony of sleek architectural design and functional spaces.",
    srcImage:
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project1%2FPHOTO-2024-08-21-12-45-24.jpg?alt=media&token=032e173c-2c8c-421f-9eed-5324cf5ad148",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project1%2FPHOTO-2024-08-21-12-45-24.jpg?alt=media&token=032e173c-2c8c-421f-9eed-5324cf5ad148",
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project1%2FPHOTO-2024-08-21-12-45-25.jpg?alt=media&token=e8f0731d-2185-4c9a-ae06-0207d405f609",
    ],
  },
  {
    title: "Sustainable Pavilion",
    subTitile: "Sustainable Pavilion",
    description:
      "This pavilion blends natural materials with eco-friendly and modern designs.",
    srcImage:
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project3%2FPHOTO-2024-08-21-12-52-42.jpg?alt=media&token=0116246f-fc1c-45ed-b079-e63e100068f6",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project3%2FPHOTO-2024-08-21-12-52-42.jpg?alt=media&token=0116246f-fc1c-45ed-b079-e63e100068f6",
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project3%2FPHOTO-2024-08-21-12-52-41.jpg?alt=media&token=cac24b89-0e87-4346-8a41-8fd647f829e4",
    ],
  },
  {
    title: "Elegant Family Retreat",
    subTitile: "Elegant Family Retreat",
    description:
      "Designed for comfort and style, this home is perfect for modern family living.",
    srcImage:
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project4%2FPHOTO-2024-08-21-12-45-32%202.jpg?alt=media&token=4686450c-63b9-4b08-8e7e-c67537907a14",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project4%2FPHOTO-2024-08-21-12-45-32%202.jpg?alt=media&token=4686450c-63b9-4b08-8e7e-c67537907a14",
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project4%2FPHOTO-2024-08-21-12-45-33%202.jpg?alt=media&token=a44ca965-ea5a-44e6-87a1-6ad873b561fe",
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project4%2FPHOTO-2024-08-21-12-45-33%203.jpg?alt=media&token=f93e73fb-d421-48bf-9676-162f6dc6ecc4",
    ],
  },
  {
    title: "Urban Chic Residence",
    subTitile: "Urban Chic Residence",
    description:
      "A sophisticated space that combines sleek city living with cozy interiors.",
    srcImage:
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project5%2FPHOTO-2024-08-21-12-45-19.jpg?alt=media&token=36d6970b-ff8f-4ebf-a08d-84e6fe6ce1dd",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project5%2FPHOTO-2024-08-21-12-45-19.jpg?alt=media&token=36d6970b-ff8f-4ebf-a08d-84e6fe6ce1dd",
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project5%2FPHOTO-2024-08-21-12-45-21.jpg?alt=media&token=481cb7cb-1f96-446e-b4cb-93de1f4a14d7",
    ],
  },
  {
    title: "Seamless Flow Design",
    subTitile: "Seamless Flow Design",
    description:
      "This project prioritizes open-concept layouts with a focus on natural light.",
    srcImage:
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project6%2FPHOTO-2024-08-21-12-45-35.jpg?alt=media&token=10a69774-2ebd-419f-95a8-c1be4d98dd04",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project6%2FPHOTO-2024-08-21-12-45-35.jpg?alt=media&token=10a69774-2ebd-419f-95a8-c1be4d98dd04",
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project6%2FPHOTO-2024-08-21-12-45-36.jpg?alt=media&token=a2fac8ed-8302-4193-a8ae-4efc6c7d0b60",
    ],
  },
  {
    title: "Coastal Haven",
    subTitile: "Coastal Haven",
    description:
      "This beachfront design features panoramic views and serene outdoor spaces.",
    srcImage:
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project7%2FPHOTO-2024-08-21-12-45-34.jpg?alt=media&token=c0e86ff7-b8b6-43f0-8d70-b8c68257682a",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project7%2FPHOTO-2024-08-21-12-45-34.jpg?alt=media&token=c0e86ff7-b8b6-43f0-8d70-b8c68257682a",
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project7%2FPHOTO-2024-08-21-12-45-33.jpg?alt=media&token=ca415fdf-1818-4826-9b73-345f4cc50310",
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project7%2FPHOTO-2024-08-21-12-45-32.jpg?alt=media&token=16f5a7fe-9667-48eb-9322-6a30693f6941",
    ],
  },
  {
    title: "Grand Luxury Estate",
    subTitile: "Grand Luxury Estate",
    description:
      "A luxurious estate that redefines comfort with opulence and timeless elegance.",
    srcImage:
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project8%2FPHOTO-2024-08-21-12-52-42%202.jpg?alt=media&token=091528c3-e80e-4a0e-a9a8-5fa6a94231d9",
    images: [
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project8%2FPHOTO-2024-08-21-12-52-42%202.jpg?alt=media&token=091528c3-e80e-4a0e-a9a8-5fa6a94231d9",
      "https://firebasestorage.googleapis.com/v0/b/heavenly-group.appspot.com/o/project8%2FPHOTO-2024-08-21-12-52-41%203.jpg?alt=media&token=81401104-ca7e-42d5-9e22-49a0fc6dc41c",
    ],
  },
];

export default projects;
