import { useState } from "react";
import { motion } from "framer-motion";
import "./ProcessSection.scss";
import AnimatedHeading from "./AnimatedHeading";

const sections = [
  {
    number: "01",
    title: "INITIAL CONSULTATION",
    content:
      "Meet with our team to discuss your vision, requirements, and budget for your construction project.",
  },
  {
    number: "02",
    title: "SITE ASSESSMENT",
    content:
      "Our team evaluates the site carefully, considering terrain, access, and any possible challenges.",
  },
  {
    number: "03",
    title: "CUSTOM DESIGN",
    content:
      "We create tailored designs that reflect your needs, using modern and sustainable solutions.",
  },
  {
    number: "04",
    title: "COST ESTIMATION",
    content:
      "We provide a clear cost breakdown, timeline, and material needs with full transparency.",
  },
  {
    number: "05",
    title: "PROJECT PLANNING",
    content:
      "We prepare a detailed plan covering schedules, resources, and all compliance needs.",
  },
  {
    number: "06",
    title: "CONSTRUCTION",
    content:
      "Our experienced builders ensure your project comes to life with top-notch quality.",
  },
  {
    number: "07",
    title: "QUALITY ASSURANCE",
    content:
      "Regular checks guarantee that our work meets the highest standards and your vision.",
  },
  {
    number: "08",
    title: "PROJECT DELIVERY",
    content:
      "We complete your project with a final review, handover, and warranty for peace of mind.",
  },
];

export default function Section() {
  const [expandedSection, setExpandedSection] = useState<number | null>(null);

  return (
    <div className="sections">
      <AnimatedHeading />
      <div className="sections-open__container">
        {sections.map((section, index) => (
          <motion.div
            key={section.number}
            className={`
              sections-open__item 
              ${
                expandedSection === index ? "sections-open__item--expanded" : ""
              } 
              ${
                index % 2 === 0
                  ? "sections-open__item--left"
                  : "sections-open__item--right"
              }
            `}
            style={{
              width: expandedSection === index ? "100%" : "50%",
            }}
            onClick={() =>
              setExpandedSection(expandedSection === index ? null : index)
            }
            layout
            transition={{
              layout: { duration: 0.3, ease: "easeInOut" },
            }}
          >
            <div className="sections-open__content">
              <div className="sections-open__main">
                <div className="sections-open__number">[{section.number}]</div>
                <h2 className="sections-open__title">{section.title}</h2>
                <motion.div
                  animate={{ scaleX: expandedSection === index ? -1 : 1 }}
                  transition={{ duration: 0.3, ease: "easeInOut" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="sections-open__icon"
                  >
                    {index % 2 === 0 ? (
                      <path d="M9 18l6-6-6-6" />
                    ) : (
                      <path d="M15 18l-6-6 6-6" />
                    )}
                  </svg>
                </motion.div>
              </div>
              {expandedSection === index && (
                <motion.div
                  layout
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3, ease: "easeInOut" }}
                  className="sections-open__expanded"
                >
                  <p className="sections-open__description">
                    {section.content}
                  </p>
                </motion.div>
              )}
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
}
